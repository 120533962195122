
body {
	font-family: 'Anonymous Pro', monospace;
	color: #505050;
	background-color: #fbf7f1;
    margin: 0;
    font-size: 18px;
    text-transform: lowercase;
    line-height: 1.2;
}

h1, h2 {
    font-family: 'Poller One';
    text-transform: uppercase;
}
h1 {
    font-size: 64px;
    margin-bottom: 12px;
}
h2 {
    text-align: center;
    margin-bottom: 64px;
}

.external-link-icon {
    height: 16px;
    padding-left: 3px;
    position: relative;
    top: 3px;
}

nav > a {
    line-height: 1.5;
    margin: 0 8px;
}

a {
    color: #505050;
    text-underline-offset: 6px;
}

.page {
    margin: 0 auto;
    margin-bottom: 64px;
    max-width: 900px;
    display: flex;
    flex-direction: column;
}

ol {
    margin: 0;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 64px;
    font-size: 36px;
}

.footer img {
    height: 48px;
    margin: 0 16px;
    position: relative;
    top: 14px;
}

@media (max-width: 639px) {
    body {
        margin: 24px;
    }
	#app > main {
		margin: 0;
	}
    .page {
        margin-bottom: 32px;
    }
    .footer {
        margin-bottom: 48px;
    }
    nav {
        margin: 12px 0;
    }
    nav > a {
        line-height: 1;
        margin: 10px 0;
    }
    h2 {
        margin-bottom: 24px;
    }
}