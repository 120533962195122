.header {
    text-align: center;
    margin: 24px 0;
    display: flex;
    flex-direction: column;
}
.subtitle {
    margin-top: 0;
    font-size: 24px;
    display: flex;
    gap: 24px;
    justify-content: center;
}
.subtitle p {
    margin: 12px;
}
nav {
    margin-top: 24px;
    font-size: 20px;
}
@media (max-width: 639px) {
    .header {
        margin: 0;
   }
    nav {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin: 36px 0;
   }
    .subtitle {
        text-align: left;
        flex-direction: column;
        gap: 8px;
   }
    .subtitle p {
        margin: 0;
   }
    .divider {
        display: none;
   }
    h1 {
        text-align: left;
        margin: 0;
   }
}
